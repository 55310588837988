@import '../../../../index.css';
@import '../../../../style.scss';

.measures-age {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__continue {
    font-family: var(--modal-font, $modal-font);
    font-size: 14px;
    font-weight: 800;
    background-color: var(--cta-color, $cta-color);
    height: 50px;
    border-radius: var(--cta-border-radius, $cta-border-radius);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: opacity ease 0.2s;

    &:not(:disabled):hover {
      opacity: 0.8;
    }

    &:not(:disabled):hover &__icon {
      transition: all ease 0.2s;
      right: 30px;
    }

    &:disabled:hover &__icon {
      transition: all ease 0.2s;
      right: 40px;
    }

    &:not(:hover) &__icon {
      right: 40px;
      transition: all ease 0.2s;
    }

    &:disabled {
      color: white;
      background-color: #DDD;
    }

    &__icon {
      position: absolute;
    }
  }

  @media only screen and (min-width: 1000px) {
    &__continue {
      font-size: 14px;
      position: relative;
      width: 400px;
      margin-bottom: 0;
      height: 55px;

      &__icon {
        &.MuiSvgIcon-root {
          font-size: 24px;
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    &__continue {
      font-size: 14px;
      position: absolute;
      bottom: 110px;
      width: 350px;
      margin-bottom: 0;
      height: 50px;

      &__icon {
        &.MuiSvgIcon-root {
          font-size: 21px;
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {
    &__continue {
      font-size: 10px;
      position: absolute;
      bottom: 110px;
      width: 300px;
      margin-bottom: 0;
      height: 45px;

      &__icon {
        &.MuiSvgIcon-root {
          font-size: 18px;
        }
      }
    }
  }
}
